
import { renderData, formatName } from "@libraryHelpers/dataComponents.js";
import { unescape as _unescape, isObject as _isObject } from "lodash";

export default {
	name: "CoreBlockFooter",
	props: {
		site: {
			type: Object,
			default: () => {
				return {};
			},
		},
		isBuilder: {
			type: Boolean,
			default: false,
		},
		// the builder preview is set to mobile view
		isBuilderMobile: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			windowWidth: process.client ? window.innerWidth : null,
			emailForm: {
				email: null,
				disclosureChecked: false,
			},
			clonedSite: null,
			defaultLogo: {
				src: "/img/logos/nyl-logo-blue.svg",
				alt: "New York Life Insurance",
			},
		};
	},
	computed: {
		blockClasses() {
			const classList = [];
			if (this.isBuilder) {
				classList.push("footer__pointer_all");
			}
			return classList;
		},
		pageTree() {
			try {
				let pageTree;
				try {
					pageTree = this?.site?.pages.filter(
						(p) =>
							!p.isPrivate &&
							!p.parentPageId &&
							this?.site?.homepageId != p.id &&
							!JSON.parse(p.settings)?.isNavButton
					);
				} catch (err) {
					pageTree =
						this?.site?.pages.filter(
							(p) =>
								!p.isPrivate &&
								!p.parentPageId &&
								this?.site?.homepageId != p.id &&
								!p.settings?.isNavButton
						) || [];
				}
				// This adds the custom nav link button to the footer as another column.
				const navButton = this?.site?.pages?.find((p) => {
					try {
						return JSON.parse(p.settings)?.isNavButton;
					} catch {
						return p.settings?.isNavButton;
					}
				});
				if (navButton) {
					pageTree.push(navButton);
				}

				return pageTree;
			} catch (err) {
				return [];
			}
		},
		logoInternalLink() {
			return this.logoLink.startsWith("/");
		},
		logoTag() {
			if (this.logoInternalLink) {
				return "NuxtLink";
			}
			return "a";
		},
		externalLink() {
			return this.logoLink;
		},
		footer() {
			return this.site?.footer || {};
		},
		copyright() {
			return renderData(this.footer.copyright, this.clonedSite);
		},
		copyrightText() {
			return renderData(
				this.footer?.copyrightText || "",
				this.clonedSite
			);
		},
		showFooter() {
			return this.footer.showFooter || false;
		},
		showBanner() {
			return this.footer.showBanner || false;
		},
		showCopyright() {
			return this.footer.showCopyright || false;
		},
		logo() {
			const logoSrc = _isObject(this.footer.logo)
				? this.footer.logo.src
				: this.footer.logo;

			return renderData(logoSrc, this.clonedSite) || this.defaultLogo.src;
		},
		logoStyle() {
			return this.footer.logoStyle;
		},
		logoAltText() {
			const logoAlt = _isObject(this.footer.logo)
				? this.footer.logo.alt
				: this.footer.logoAltText;

			const defaultAlt =
				this.logo === this.defaultLogo.src && this.defaultLogo.alt;
			return logoAlt || defaultAlt || this.name;
		},
		logoLink() {
			return this.footer.logoLink || "/#";
		},
		hasDataNameFooter() {
			const dataName =
				renderData(this.footer.nameFooter, this.clonedSite) || "";
			return !!dataName.trim(); // the default mainnav name has spaces between vars, so it will end up something like "  " even if there are no values
		},
		nameFooter() {
			const formattedName = formatName(
				this.footer.nameFooter,
				this.site.user?.ddcUserData
			);
			return this.hasDataNameFooter
				? _unescape(renderData(formattedName, this.clonedSite))
				: this.site.title;
		},
		isManagingPartner() {
			if (
				this.site.user?.ddcUserData?.marketerTitleTpDesc?.toLowerCase() ===
				"managing partner"
			) {
				return true;
			}

			return false;
		},
		overlineFooter() {
			if (this.isGO) {
				return this.goHeadquarters + " General Office";
			}
			return _unescape(
				renderData(this.footer.overlineFooter, this.clonedSite)
			);
		},
		jobTitleFooter() {
			if (this.isManagingPartner) {
				return "Managing Partner";
			}
			return renderData(this.footer.jobTitleFooter, this.clonedSite);
		},
		showUsernameFooter() {
			return this.footer.showUsernameFooter;
		},
		showOverlineFooter() {
			return this.footer.showOverlineFooter;
		},
		showTitleFooter() {
			return this.footer.showTitleFooter;
		},
		isDBA() {
			return !!this.site.user?.ddcUserData?.dba1Nm || false;
		},
		isRegRep() {
			return this.site.user?.ddcUserData?.regRepInd === "Y";
		},
		isGO() {
			return this.site.user?.employeeType === "GO";
		},
		goHeadquarters() {
			return this.site.user?.ddcUserData?.orgUnitDesc;
		},
		logoClass() {
			return `brand_image brand_image--${this.logoStyle}`;
		},
		hasSocialLink() {
			return this.facebook || this.linkedin || this.twitter;
		},
		facebook() {
			if (!this.footer?.["facebook-show"]) return "";
			return renderData(this.footer.facebook, this.clonedSite);
		},
		linkedin() {
			if (!this.footer?.["linkedin-show"]) return "";
			return renderData(this.footer.linkedin, this.clonedSite);
		},
		instagram() {
			return this.footer.instagram;
		},
		twitter() {
			if (!this.footer?.["twitter-show"]) return "";
			return renderData(this.footer.twitter, this.clonedSite);
		},
		youtube() {
			return this.footer.youtube;
		},
		showSignUpForm() {
			return this.footer.showSignUpForm;
		},
		signUpFormDisclosure() {
			return this.footer.signUpFormDisclosure;
		},
		hasEmailDisclosure() {
			return (
				this.signUpFormDisclosure &&
				this.signUpFormDisclosure !== "<p></p>"
			);
		},
		navItems() {
			return this?.site?.navigation?.links || [];
		},
		isMobile() {
			if (this.isBuilderMobile) {
				return true;
			}
			// breakpoint needs to correspond with Core.Builder/assets/scss/mixins.scss
			const breakpoint = 992;
			const isMobile = this.windowWidth && this.windowWidth < breakpoint;

			if (isMobile || isMobile === null) {
				return true;
			}
			return false;
		},
	},
	watch: {
		// Connects FINRA banner with copyright and updates data for mustache templates
		showBanner() {
			this.addDataToSite();
		},
	},
	mounted() {
		window.addEventListener("resize", this.onResize);
	},
	created() {
		this.addDataToSite();
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.onResize);
	},
	methods: {
		handleToggle() {
			if (
				this.isBuilder &&
				!this.$store.state.interface.isLeftRailClosing
			) {
				if (!this.$store.state.interface.activeSidebarKey) {
					this.$store.commit(
						"interface/toggleActiveSidebarKey",
						"footer"
					);
				} else {
					this.$store.commit("interface/setActiveSidebarKey");
				}

				this.$store.commit(
					"interface/setRightRailOpening",
					!this.$store.state.interface.isRightRailOpening
				);
				this.$store.commit("site/clearSelectedBlockIds"); // will clear selected blocks without influencing the active sidebar key
				setTimeout(() => {
					const targetElement = document.querySelector(
						".editor-main-navigation"
					);
					if (targetElement)
						targetElement.scrollIntoView({ behavior: "smooth" });
				}, 500);
			}
		},
		onResize() {
			this.windowWidth = window.innerWidth;
		},
		submitForm() {
			//console.log(this.emailForm);
		},
		addDataToSite() {
			// here we can add various data points to use in the footer via mustache templates
			// needs to be done in created so that the computed data is available in the mustache templates
			const year = process.client ? new Date().getFullYear() : null;

			// Show 4 line version copyright if FINRA banner is toggled on and some other conditions
			const needsCopyright =
				(this.isRegRep && !this.isDBA) || this.isGO || this.showBanner;
			this.clonedSite = { year, needsCopyright, ...this.site };
		},
	},
};
